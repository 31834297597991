<template>
  <div>
    <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="5"
        :dark="dark"
        pagination
    >
      <template #name="{item}"><td class="text-left w300">{{item.code}}</td></template>
      <template #boxes="{item}"><td class="text-center w100">{{item.sold_boxes | currency}} / {{item.boxes | currency}}</td></template>
      <template #profit="{item}"><td class="text-right w100">{{item.profit | currency}}</td></template>
    </CDataTable>
  </div>
</template>

<script>
import ws from '../../services/customers';
import store from '../../store'
import router from '../../router/index'

export default {
  name: 'TopCustomersTable',
  components: { },
  props: {
    items: Array,
    permissions: Object,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'name', label: 'Cliente'},
          {key: 'boxes', label: 'Cajas'},
          {key: 'profit', label: 'Ganancia'}
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
    }
  },
  methods: {
    showSales (item) {
      router.push({path: '/customers/'+item.id+'/'+(this.slugify(item.name))+'/sales'});
    },
    storeModal () {
      this.$refs.customerModal.storeModal();
    },
    updateModal (item) {
      this.$refs.customerModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Cliente", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    importModal () {
      this.$refs.importModal.importModal();
    },
    async importProcess(data) {
      this.$emit("refresh", data );
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>